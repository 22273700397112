<template>
  <custom-drop-down-menu>
    <template #header>
      <div class="header-edit-view-immo">
        <IconColumns />
        <div class="header-title">
          {{ $t('edit_view') }}
        </div>
        <div class="vertical-line"></div>
      </div>
    </template>
    <template #body>
      <div class="dropdown-edit-view-list" v-if="list && list.length">
        <Container
          :drop-placeholder="dropPlaceholderOptions"
          :lock-axis="'y'"
          @drop="onColumnDropProjects"
          drag-handle-selector=".column-drag-container"
        >
          <Draggable
            v-for="item in list"
            :key="item.id"
            :data-id="item.id"
            class="draggable-item"
          >
            <div class="draggable-content">
              <div class="d-flex justify-space-between d-content-view ml-2">
                <div>
                  <font-awesome-icon
                    :icon="['fas', 'grip-dots-vertical']"
                    class="icon-move column-drag-container"
                  />
                  <span class="item-name">{{ item.name }}</span>
                </div>
                <v-checkbox
                  class="input-checkbox check-visible-imo-modal mb-1"
                  color="#5C2DD3"
                  hide-details
                  v-model="item.visibility"
                  :true-value="1"
                  :false-value="0"
                  @change="handleDropdownItemClick(item)"
                />
              </div>
            </div>
          </Draggable>
        </Container>
      </div>
      <div class="dropdown-edit-view-list text-center" v-else>
        {{ $t('noResult') }}
      </div>
    </template>
  </custom-drop-down-menu>
</template>

<script>
import customDropDownMenu from '../immoTable/component/customDropDownMenu.vue'
import IconColumns from '../IconSvg/Columns.vue'
import { Container, Draggable } from 'vue-smooth-dnd'

export default {
  props: {
    list: { required: true, default: () => [] },
    handleDropdownItemClick: { default: () => {} },
    updateOrderFields: { default: () => {} }
  },
  data() {
    return {
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      }
    }
  },
  methods: {
    onColumnDropProjects(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }

      let orderFields = this.applyDrag(this.list, dropResult)
      this.updateOrderFields(orderFields, dropResult)
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    }
  },
  components: {
    IconColumns,
    Draggable,
    Container,
    customDropDownMenu
  }
}
</script>

<style lang="scss" scoped>
.draggable-item.dragging {
  position: relative;
  z-index: 9999;
  opacity: 0.8;
}

.draggable-item .draggable-content {
  padding: 10px;
}

.draggable-item.dragging .item-name {
  color: rgba(0, 0, 0, 0.87);
}

.dropdown-edit-view-list {
  position: relative;
  max-height: 200px;
  max-width: 250px;
  background: #fff;
}

.header-edit-view-immo {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  line-height: 1rem;
  gap: 7px;
  letter-spacing: 0px;
  color: #2f2f2f;
  padding: 0px 12px 0px 12px;
  align-items: center;
  justify-content: flex-start;
}

.header-edit-view-immo:hover {
  background: #f1f5f9 !important;
}

.header-title {
  font-size: 12px;
  line-height: 9px;
  cursor: pointer;
}

.vertical-line {
  border-right: 1px solid #c9c8d3;
}

.icon-move {
  color: #7f8c8d !important;
  margin-right: 10px;
}

.check-visible-imo-modal .v-icon.v-icon {
  font-size: 20px !important;
}

.d-content-view {
  font-weight: 500 !important;
  letter-spacing: 0em;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
}
</style>
